@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SimplonNorm-Regular';
  src: url('../src/fonts/SimplonNorm-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'SimplonNorm-Medium';
  src: url('../src/fonts/SimplonNorm-Medium.otf') format('truetype');
}

@layer base {
  body {
    font-family: 'SimplonNorm-Regular';
  }
  #lact-text {
    font-family: 'SimplonNorm-Regular';
  }
  p {
    font-family: 'SimplonNorm-Regular' !important;
  }
  .text-regular {
    font-family: 'SimplonNorm-Regular';
  }
  .text-regular-italic {
    font-family: 'SimplonNorm-RegularItalic';
  }
  .text-medium {
    font-family: 'SimplonNorm-Medium';
  }
  .text-light {
    font-family: 'SimplonNorm-Light';
  }
  .text-light-italic {
    font-family: 'SimplonNorm-LightItalic';
  }
  .text-semibold {
    font-family: 'SFProText-Semibold';
  }
  .text-bold {
    font-family: 'SimplonNorm-Bold';
  }
  .text-bold-italic {
    font-family: 'SimplonNorm-BoldItalic';
  }
  .bg-primary {
    background-color: #195a6d;
  }
  .w-360 {
    width: 360px;
  }
  .h-520 {
    height: 520px;
  }
  .h-380 {
    height: 380px;
  }
  .h-500 {
    height: 500px;
  }
  .border-width-1 {
    border-width: 1px;
  }
  .text-3xl {
    font-size: 32px !important;
  }
}

@layer components {
  @font-face {
    font-family: 'SimplonNorm-Regular';
    src: url('../src/fonts/SimplonNorm-Regular.otf') format('truetype');
  }
  body {
    font-family: 'SimplonNorm-Regular' !important;
  }
  #lact-text {
    font-family: 'SimplonNorm-Regular';
  }
  #lact-text-semibold {
    font-weight: 700;
    font-family: 'SimplonNorm-Regular';
  }
  p {
    font-family: 'SimplonNorm-Regular' !important;
  }
  span {
    font-family: 'SimplonNorm-Regular';
  }
  #text-semi-bold {
    font-weight: 700 !important;
    font-family: 'SimplonNorm-Regular' !important;
  }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    background-color: #f4f5f6;
    font-family: 'SimplonNorm-Regular' !important;
  }
  .form-control {
    border: none !important;
    border-width: 0 !important;
    background-color: '#F4F5F6';
    border-radius: 12px !important;
    font-family: 'SimplonNorm-Regular' !important;
  }
  .form-control:focus {
    box-shadow: none !important;
    border-color: none !important;
    background-color: #f4f5f6 !important;
  }

  .form-floating > .form-control {
    background-color: '#F4F5F6' !important;
  }
  .form-label {
    font-family: 'SimplonNorm-Regular' !important;
  }
  .offcanvas.offcanvas-end {
    width: 578px !important;
  }
  .offcanvas.offcanvas-bottom {
    height: 100% !important;
  }
  .modal-content {
    border-radius: 24px !important;
    border-color: white !important;
    border: white !important;
    width: 64% !important;
    padding: 48px !important;
  }
  .modal-dialog-centered {
    justify-content: center !important;
  }
  input {
    outline: none;
    font-family: 'SimplonNorm-Regular' !important;
  }
  textarea {
    outline: none;
    resize: none !important;
    font-family: 'SimplonNorm-Regular' !important;
  }
  .progress-bar {
    background-color: #195a6d !important;
  }
}
